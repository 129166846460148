import React, { useEffect } from 'react';
import { Box } from '@mui/material';

import PageNavigationHeader from './PageNavigationHeader';
import PageLeadingView from './PageLeadingView';
import PageAboutUsView from './PageAboutUsView';
import PagePricingView from './PagePricingView';
import PageTradingView from './PageTradingView';
import PageFeaturesView from './PageFeaturesView';
// import PageDebitCardView from './PageDebitCardView';

const LandingPage = () => {
  useEffect(() => {
    const onScroll = () => {
      const value = window.scrollY;
      const els = document.getElementsByClassName('animate__target');

      for (const el of els) {
        if (value + window.innerHeight >= el.offsetTop) {
          if (!el.classList.contains('animate__animated')) {
            el.className =
              el.className + ' animate__animated animate__fadeInUp';
          }
        }
      }
    };
    window.addEventListener('scroll', onScroll);

    return window.removeEventListener('scroll', onscroll);
  }, []);

  return (
    <Box>
      <PageNavigationHeader />
      <PageLeadingView />
      <PageAboutUsView />
      <PagePricingView />
      <PageTradingView />
      <PageFeaturesView />
      {/* <PageDebitCardView /> */}
    </Box>
  );
};

export default LandingPage;
