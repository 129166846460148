import React from 'react';
import {
  Box,
  ButtonBase,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

import { colors } from '../../config/constants';

import { ReactComponent as SvgFeatureIcon1 } from './feature-1.svg';
import { ReactComponent as SvgFeatureIcon2 } from './feature-2.svg';
import { ReactComponent as SvgFeatureIcon3 } from './feature-3.svg';
import { ReactComponent as SvgFeatureIcon4 } from './feature-4.svg';
import { ReactComponent as SvgFeatureIcon5 } from './feature-5.svg';
import { ReactComponent as SvgFeatureIcon6 } from './feature-6.svg';

const PageFeaturesView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container
      sx={{
        bgcolor: colors.white,
        minHeight: '100vh',
        pt: 8,
        pb: 5,
      }}
      id="section-features"
    >
      <Typography
        fontSize={42}
        fontWeight={700}
        lineHeight="48px"
        colorscheme="gray.900"
        textAlign="center"
      >
        {t('Features')}
      </Typography>
      <Typography
        mt={4}
        fontSize={18}
        lineHeight="28px"
        colorscheme="gray.600"
        textAlign="center"
        fontWeight="bold"
      >
        {t('VALUE FOR VALUE EXCHANGE AT ITS ABSOLUTE BEST.')}
        <br />
        {t('A UNIQUE, COMMODITY CLOSED-LOOP ECO-SYSTEM FOR COMMODITY BUYERS AND SELLERS.')}
        <br />
        <br />
      </Typography>
      <Box
        mt={5}
        pb={5}
      >
        <Grid
          container
          sx={{
            '--Grid-borderWidth': '1px',
            // borderTop: 'var(--Grid-borderWidth) solid',
            // borderLeft: 'var(--Grid-borderWidth) solid',
            borderColor: 'divider',
            '& > div': {
              borderRight: 'var(--Grid-borderWidth) solid',
              borderBottom: 'var(--Grid-borderWidth) solid',
              borderColor: 'divider',
            },
            '& > div:last-child': {
              borderRight: 'none',
              borderBottom: 'none',
            },
            '& > div:nth-child(3)': {
              borderRight: 'none',
            },
            '& > div:nth-child(4)': {
              borderBottom: 'none',
            },
            '& > div:nth-child(5)': {
              borderBottom: 'none',
            },
            ...(isMd && {
              '& > div:nth-child(2)': {
                borderRight: 'none',
              },
              '& > div:nth-child(3)': {
                borderRight: '',
              },
              '& > div:nth-child(4)': {
                borderRight: 'none',
                borderBottom: '',
              },
            }),
            ...(isSm && {
              '& > div:nth-child(1)': {
                borderRight: 'none',
              },
              '& > div:nth-child(2)': {
                borderRight: 'none',
              },
              '& > div:nth-child(3)': {
                borderRight: 'none',
              },
              '& > div:nth-child(4)': {
                borderRight: 'none',
              },
              '& > div:nth-child(5)': {
                borderRight: 'none',
              },
              '& > div:nth-child(6)': {
                borderRight: 'none',
              },
            }),
          }}
          spacing={2}
        >
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <Stack
              alignItems="center"
              pt={4}
              pb={4}
              className="animate__target "
            >
              <SvgFeatureIcon1 />
              <Typography
                fontSize={21}
                fontWeight={700}
                lineHeight="28px"
                colorscheme="gray.900"
                mt="57px"
                textAlign="center"
              >
                {t('Protection & Privacy')}
              </Typography>
              <Typography
                mt={3}
                fontSize={16}
                lineHeight="26px"
                colorscheme="gray.600"
                textAlign="center"
                maxWidth={330}
              >
                {t(
                  'Our commodities value is safeguarded with a minimum price threshold, supported by secure blockchain technology and strict regulatory compliance. Operating within a closed-loop system, our commodities are not traded on public exchanges.',
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <Stack
              alignItems="center"
              pt={4}
              pb={4}
              className="animate__target "
            >
              <SvgFeatureIcon2 />
              <Typography
                fontSize={21}
                fontWeight={700}
                lineHeight="28px"
                colorscheme="gray.900"
                mt="57px"
                textAlign="center"
              >
                {t('Instant Utility')}
              </Typography>
              <Typography
                mt={3}
                fontSize={16}
                lineHeight="26px"
                colorscheme="gray.600"
                textAlign="center"
                maxWidth={330}
              >
                {t(
                  'Easily log on to transact. Our platform facilitates swift, secure, high-value commodity trades, including raw materials, finished products, and specialized goods.',
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <Stack
              alignItems="center"
              pt={4}
              pb={4}
              className="animate__target "
            >
              <SvgFeatureIcon3 />
              <Typography
                fontSize={21}
                fontWeight={700}
                lineHeight="28px"
                colorscheme="gray.900"
                mt="57px"
                textAlign="center"
              >
                {t('Cash Independence')}
              </Typography>
              <Typography
                mt={3}
                fontSize={16}
                lineHeight="26px"
                colorscheme="gray.600"
                textAlign="center"
                maxWidth={330}
              >
                {t(
                  'Transactions can be completed using 100% of our platform’s currency—no cash liquidity is required for purchasing valuable commodities.',
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <Stack
              alignItems="center"
              pt={4}
              pb={4}
              className="animate__target "
            >
              <SvgFeatureIcon4 />
              <Typography
                fontSize={21}
                fontWeight={700}
                lineHeight="28px"
                colorscheme="gray.900"
                mt="57px"
                textAlign="center"
              >
                {t('Low Transaction Costs')}
              </Typography>
              <Typography
                mt={3}
                fontSize={16}
                lineHeight="26px"
                colorscheme="gray.600"
                textAlign="center"
                maxWidth={330}
              >
                {t(
                  "Transaction costs are much lower than traditional finance methods. We enhance cost efficiency, and transaction fees can be paid using the OODL token, streamlining the process and adding further convenience. By leveraging advanced technology and the OODL token, we ensure members enjoy cost-effective and seamless trades, making us a superior choice for trades.",
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <Stack
              alignItems="center"
              pt={4}
              pb={4}
              className="animate__target "
            >
              <SvgFeatureIcon5 />
              <Typography
                fontSize={21}
                fontWeight={700}
                lineHeight="28px"
                colorscheme="gray.900"
                mt="57px"
                textAlign="center"
              >
                {t('Modernised Global Commodity Trades')}
              </Typography>
              <Typography
                mt={3}
                fontSize={16}
                lineHeight="26px"
                colorscheme="gray.600"
                textAlign="center"
                maxWidth={330}
              >
                {t(
                  'We are bringing the global commodity industry into the digital age with a focus on security. Our platform uses advanced technology to ensure fast, transparent, and secure transactions for fiat currencies, cryptocurrencies, and physical commodities. This guarantees efficient and reliable trades.',
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <Stack
              alignItems="center"
              pt={4}
              pb={4}
              className="animate__target "
            >
              <SvgFeatureIcon6 />
              <Typography
                fontSize={21}
                fontWeight={700}
                lineHeight="28px"
                colorscheme="gray.900"
                mt="57px"
                textAlign="center"
              >
                {t('Exclusive Access to Premium Commodities')}
              </Typography>
              <Typography
                mt={3}
                fontSize={16}
                lineHeight="26px"
                colorscheme="gray.600"
                textAlign="center"
                maxWidth={330}
              >
                {t(
                  'We offer vetted members access to commodities such as precious metals, stones, coffee, rice, sugar and flour. Vetted sellers list their assets with detailed information so vetted buyers can easily enquire and purchase. We ensure secure and member-friendly cross-border trades.',
                )}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Stack
        direction="row"
        justifyContent="center"
      >
        <ButtonBase
          sx={{
            px: 4,
            py: 2,
          }}
        >
          <Typography
            fontSize={21}
            fontWeight={700}
            colorscheme="black"
          >
            {t('Trade with us now')}
          </Typography>
        </ButtonBase>
      </Stack>
    </Container>
  );
};

export default PageFeaturesView;
